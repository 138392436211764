<template>
  <section class="controller-app">
    <div class="controller-app__container">
      <a href="https://www.voicemod.net">
        <img
          src="@/assets/images/voicemod-white.svg"
          alt="voicemod logo"
          class="controller-app__voicemod"
        >
      </a>
      <h1 class="controller-app__title">
        {{ $t('controllerApp.title') }}
      </h1>
      <p class="controller-app__paragraph">
        {{ $t('controllerApp.text1') }}
      </p>
      <p class="controller-app__paragraph">
        {{ $t('controllerApp.text2') }}
      </p>
      <div class="controller-app__mobile-stores">
        <div
          class="controller-app__store"
          @click="openAppleStore"
        >
          <img
            src="@/assets/images/app-store.png"
            alt="apple store"
          >
        </div>
        <div
          class="controller-app__store"
          @click="openPlayStore"
        >
          <img
            src="@/assets/images/play-store.png"
            alt="play store"
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mParticleMetric } from '@/metrics';
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    mounted() {
        mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_VM_CONTROLLER_VIEW);
    },
    methods: {
        openAppleStore() {
            mParticleMetric.track(mParticleMetric.EVENTS.STORE_BUTTON_CLICKED, {os: 'ios'});
            window.location.assign(URL_CONSTANTS.APPLE_STORE);
        },
        openPlayStore() {
            mParticleMetric.track(mParticleMetric.EVENTS.STORE_BUTTON_CLICKED, {os: 'android'});
            window.location.assign(URL_CONSTANTS.ANDROID_STORE);
        },
    },
};
</script>

<style src="./ControllerAppView.scss" lang="scss" />
